<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <div v-on="$listeners" class="btn-control" :class="{ 'disabled' : disabled }">
    <svg viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
      class="feather feather-plus">
      <line x1="12" y1="5" x2="12" y2="19"></line>
      <line x1="5" y1="12" x2="19" y2="12"></line>
    </svg>
  </div>
</template>

<style scoped>
.btn-control {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
  /* transition: 0.3s; */
}

.close {
  transform: rotate(45deg);
}

.btn-control.hover {
  background: rgba(255, 255, 255, 0.2);
} 
.disabled {
  cursor: not-allowed;
}
.disabled.hover {
  background: transparent;
}

.feather {
  width: 100%;
  height: 100%;
  stroke: white;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  transition: 0.3s;
}
</style>