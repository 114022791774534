import { render, staticRenderFns } from "./LegoSelectCollaborator.vue?vue&type=template&id=65779fe2&scoped=true"
import script from "./LegoSelectCollaborator.vue?vue&type=script&lang=js"
export * from "./LegoSelectCollaborator.vue?vue&type=script&lang=js"
import style0 from "./LegoSelectCollaborator.vue?vue&type=style&index=0&id=65779fe2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65779fe2",
  null
  
)

export default component.exports