<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    waiting: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    toggleEvent() {
      if( this.disabled || this.waiting) return
      this.$emit('input', !this.value)
    }
  },
  computed: {
    message() {
      return this.value ? 'Finalizado' : 'Marcar como finalizado'
    },
  }
}
</script>
<template>
  <button :class="{ checked: this.value, disabled: disabled, waiting: waiting  }" class="check-button" @click="toggleEvent" :disabled="disabled" >
    <svg v-show="this.value" height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17.837 17.837" xml:space="preserve">
      <g>
        <path  d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27
		c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0
		L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z" />
      </g>
    </svg>
  </button>
</template>
<style scoped>
.check-button {

  background: transparent;
  border: none;
  border: solid 1px rgba(255, 255, 255, 0.2);
  color: silver;
  border-radius: 5px;
  cursor: pointer;
  transition: all .3s;
}
.check-button svg{
  width: 100%;
  height: 100%;
  transform: scale(2);
  fill: var(--lego-hsuccess);
}

.check-button.checked {
  /* verde success */
  background-color: var(--lego-dsuccess);
  color: var(--lego-hsuccess);
  border: solid var(--lego-hsuccess) 1px;
}

.disabled {
  cursor: not-allowed;
  border: none;
}

.waiting {
  cursor: progress;
  border: none;
}


</style>