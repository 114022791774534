<script>
import Tooltip2Vue from '@/components/Tooltip2Vue/Tooltip2Vue';
import LegoSelectCollaborator from '@/components/Lego/LegoSelectCollaborator.vue';
import LegoBtn from '@/components/Lego/LegoBtn.vue';

export default {
  components: {
    Tooltip2Vue,
    LegoSelectCollaborator,
    LegoBtn
  },
  props: {
    collaborators: {
      type: Array,
      required: false,
      default: () => []
    },
    card: {
      type: Object,
      required: true
    },
    lite: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      deleting: []
    }
  },
  computed: {
    roleLevel() {
      return this.$store.state.lego.currencyRole.level
    },
    assignments() {
      return this.card.assignments
    },
    selectedCollaborators() {
      if (this.assignments != undefined) {
        return this.assignments.map(assignment => this.collaborators.find(c => c.id == assignment.collaboratorId))
      } else {
        return []
      }
    },
    isDependent() {
      return this.card.legoColumnId == null
    }
  },
  methods: {
    addAssignment(collaborator) {
      const assignment = {
        collaboratorId: collaborator.id,
        personaId: collaborator.personaId,
        LegoCardId: this.card.id
      }
      this.$store.dispatch('lego/addAssignment', assignment)

    },
    async deleteAssignment(assignment) {
      this.deleting.push(assignment.id)
      await this.$store.dispatch('lego/deleteAssignment', assignment)
      this.deleting = this.deleting.filter(id => id != assignment.id)
    }
  }
}
</script>

<template>
  <Tooltip2Vue position="bottom">
    <slot>
      <LegoBtn>
        <span><i class="fa-regular fa-user"></i></span>
        <span v-if="lite">
          {{ selectedCollaborators.length }}
        </span>
        <span v-else>
          {{ selectedCollaborators.length ? selectedCollaborators.length + ' Resp.' : 'Responsables' }}
        </span>
      </LegoBtn>
    </slot>
    <template #tooltip>
      <div class="tp-container">
        <div class="mb-2">
          <p>
            <span>Responsables</span>
          </p>
          <div v-if="roleLevel < 3 && isDependent">
            <p>
              No es posible asignar responsables a tareas que dependen de otras.
            </p>
            <p>
              Por favor, asigna responsables en la tarea.
            </p>
          </div>

        </div>
        <LegoSelectCollaborator v-if="roleLevel < 3 && !isDependent" :selectedCollaborators="selectedCollaborators"
          :collaborators="collaborators" @input="addAssignment" />
        <div class="responsables">
          <div class="responsable" v-for="assignment in assignments" :key="assignment.id">
            <div class="persona">
              <div>
                <i class="fa-regular fa-user"></i>
              </div>
              <div>
                <span>
                  {{ assignment.collaborator.name }}
                </span>
              </div>
            </div>
            <div @click="deleteAssignment(assignment)" v-if="roleLevel < 3 && !isDependent">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon-minus" viewBox="0 0 24 24" stroke-width="1.5"
                fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <line x1="5" y1="12" x2="19" y2="12" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Tooltip2Vue>
</template>

<style scoped>
.btn-shadow {
  padding: .5rem;
  border: solid 1px rgba(255, 255, 255, 0.25);
  background-color: transparent;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  gap: .5rem;
  font-size: .8rem;
}

.tp-container {
  border: solid 1px rgba(255, 255, 255, 0.25);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.8);
  padding: 1rem;
  border-radius: 8px;
  cursor: auto;
  width: 300px;
  text-align: start;
}

.responsable:first-of-type {
  margin-top: 1rem;
}

.responsable {
  display: flex;
  padding: .75rem 0;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;
}

.icon-minus {
  transition: .3s;
  height: 24px;
  width: 24px;
  border-radius: 5px;
  cursor: pointer;
  stroke: silver;
}

.icon-minus:hover {
  /* background-color: rgba(255,255,255,.2); */
  stroke: var(--lego-danger)
}

.persona {
  display: flex;
  gap: .5rem;
}
</style>