<script>
import Tooltip2Vue from '@/components/Tooltip2Vue/Tooltip2Vue.vue';
import TreeDots from '@/components/buttons/TreeDots.vue';

export default {
  props: {
    position: {
      type: String,
      default: 'bottom'
    }
  },
  components: {
    Tooltip2Vue,
    TreeDots
  },
}
</script>

<template>
  <Tooltip2Vue :position="position">
    <slot name="button">
      <TreeDots />
    </slot>
    <template #tooltip>
      <div class="tp-board">
        <slot>
          <div>
            Prueba tooltip
          </div>
        </slot>
      </div>
    </template>

  </Tooltip2Vue>
</template>

<style scoped>
.tp-board {
  background-color: var(--lego-bg);
  border-radius: 0.5rem;
  border: solid 1px rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.8);
  font-size: 1rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.tp-board > :deep(*) {
  display: block;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: 0.3s;
}
.cancel-default-styles .tp-board > :deep(*) {
  cursor: default;
}

.tp-board > :deep(*):hover {
  background-color: var(--lego-dark-hover);
}


.cancel-default-styles .tp-board > :deep(*):hover {
  background-color: transparent;
}
</style>