<script>
import AddBtn from '@/components/buttons/AddBtn.vue'

export default {
  props: {
    title: {
      type: String,
      default: 'Titulo'
    }
  },
  components: {
    AddBtn
  },
  methods: {
    close() {
      console.log('close');
      this.$router.back()
    }
  }

}
</script>

<template>
  <div class="lego-back-modal" @mousedown="close">
    <div class="lego-modal" @mousedown.stop>
      <slot name="header" :close="close">
        <header class="header">
          <div>
            <slot name="header-start">
              <p class="title">
                <slot name="title">
                  {{ title }}
                </slot>
              </p>
            </slot>
          </div>
          <AddBtn class="close" @click="close" />
        </header>
      </slot>
      <slot name="default" :close="close">

        <span>
          Name slot: Default
        </span>

      </slot>
    </div>
  </div>
</template>

<style scoped>
.lego-back-modal {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 3;
  overflow: auto;
  display: flex;
  padding: 1rem 0;
}

.lego-back-modal.full-height .lego-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.lego-modal {
  margin: auto;
  width: 90%;
  max-width: min(768px, 90%);
  background-color: var(--lego-bg);
  padding: 1.5rem;
  border-radius: 3px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  /* border-bottom: solid 1px rgba(255, 255, 255, 0.25); */
  /* padding-bottom: 1rem; */
}

.title {
  margin: 0;
  font-size: 1.6rem;
}
</style>