<script>
import Tooltip2Vue from '@/components/Tooltip2Vue/Tooltip2Vue';

export default {
  components: {
    Tooltip2Vue
  },
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      primos: [0, 1,2,3,5,7,11,13,17,19,23,29,31,37,41,43,47],
    }
  },
  computed: {
    effort() {
      return this.card.effort
    },
    roleLevel() {
      return this.$store.state.lego.currencyRole.level
    }
  },
  methods: {
    input(effort, close) {
      if( effort === null || effort === undefined ) return
      const card = Object.assign({}, this.card, { effort })
      this.$store.dispatch('lego/setAsyncItemInCard', card)
      close()
    },
  }
}

</script>

<template>
  <Tooltip2Vue position="bottom" :deleteTooltip="roleLevel > 2">
    <div class="btn-shadow">
      <span><i class="fas fa-dumbbell"></i></span>
      <span>{{ effort ? effort : 'Esfuerzo'}}</span>
    </div>
    <template #tooltip="{close}">
      <div class="tp-container">
        <div class="mb-2">
          <span>Esfuerzo</span>
        </div>
        <v-select class="lego" :options="primos" @input="input($event, close)" :value="effort"></v-select>
      </div>
    </template>
  </Tooltip2Vue>
</template>

<style scoped>
.btn-shadow {
  padding: .5rem;
  border: solid 1px rgba(255, 255, 255, 0.25);
  background-color: transparent;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  gap: .5rem;
  font-size: .8rem;
}

.tp-container {
  border: solid 1px rgba(255, 255, 255, 0.25);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.8);
  padding: 1rem;
  border-radius: 8px;
  cursor: auto;
  width: 300px;
  text-align: start;
}

</style>