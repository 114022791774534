<script>
import LegoBtn from '@/components/Lego/LegoBtn.vue'

export default {
  components: {
    LegoBtn
  },
  props: {
    card: {
      type: Object,
      required: true
    }
  }
}

</script>

<template>
  <router-link :to="{ name: 'LegoCardProcess', params: { process: 'Adjuntos', cardId: card.id }}">
    <LegoBtn>
      <span>
        <i class="fa-solid fa-paperclip"></i>
      </span>
      Adjuntos
    </LegoBtn>
  </router-link>
</template>