<script>
import lodash from "lodash";

export default {
  props: {
    remotePath: {
      type: String,
      default: '',
    },
    callbackBeforeSubmit: {
      type: Function,
      default: () => { }
    },
    callbackAfterSubmit: {
      type: Function,
      default: () => { }
    },
    renameFile: {
      type: String,
    },
    hiddenFooter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      file: null,
      fileName: null,
      dotExtension: null,
      pathCleaned: null,
      isSaveFile: false,
      uploading: false,
      waitSmarter: false
    }
  },
  computed: {
    fileBackground() {
      if (this.file) {
        // Dependiendo del tipo de archivo, se puede cambiar el backgroundColor
        if (this.file.type.includes('image')) {
          return {
            // estilos para que la imagen de bacground se adapte al contenedor
            backgroundImage: `url(${URL.createObjectURL(this.file)})`,
            backgroundColor: '#f5f5f5',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
            
          }
        } else if (this.file.type.includes('pdf')) {
          return {
            backgroundColor: '#d32f2f',
          }
        } else if (this.file.type.includes('.document')) {
          return {
            backgroundColor: '#2b579a',
          }
        } else if (this.file.type.includes('.sheet')) {
          return {
            backgroundColor: '#217346',
          }
        } else if (this.file.type.includes('.presentation')) {
          return {
            backgroundColor: '#d24726',
          }
        } else {
          return {
            backgroundColor: '#f5f5f5',
          }
        }
      } else {
        return {
          backgroundImage: 'none',
          backgroundColor: 'transparent'
        }
      }
    },
    pathAfterSaveInRemote() {
      if (this.isSaveFile) {
        return "https://www.helexiumfiles.com/" + this.pathCleaned + "/" + this.fileName + this.dotExtension;
      } else {
        return ''
      }
    }
  },
  methods: {
    cleanText(fileName) {
      let sinTildes = lodash.deburr(fileName);
      let sinEspacios = sinTildes.replace(/\s+/g, "");
      return sinEspacios;
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        this.validateAndProcessFile(selectedFile);
      }
    },
    handleDrop(event) {
      const selectedFile = event.dataTransfer.files[0];
      if (selectedFile) {
        this.validateAndProcessFile(selectedFile);
      }
    },
    validateAndProcessFile(file) {
      // si pasa todas las validaciones
      this.file = file;
      const indexLastDot = file.name.lastIndexOf('.');
      this.fileName = file.name.substring(0, indexLastDot);
      this.dotExtension = file.name.substring(indexLastDot);
      this.$emit("rename", this.fileName )

      this.$emit("fileInCache", this);
    },

    async saveInSmarter() {
      if (this.renameFile) {
        this.fileName = this.renameFile;
      }

      this.waitSmarter = true
      this.pathCleaned = this.cleanText(this.remotePath);
      const remoteFormatPath = this.pathCleaned.split("/").join("|");
      const url = '.|site1|' + remoteFormatPath;

      const fileModel = {
        filename: this.fileName + this.dotExtension,
        file: this.file,
        url: url
      }

      this.isSaveFile = await this.$store.dispatch("onDropFile", fileModel);
      this.waitSmarter = false
    },

    async onSubmit() {
      if( !this.file ) {
        alert("No se ha cargado un documento")
        return
      }

      this.uploading = true

      await this.callbackBeforeSubmit(this);

      await this.saveInSmarter();
      
      await this.callbackAfterSubmit(this);
      this.uploading = false
      this.onCancel()

    },
    onCancel() {
      this.file = null;
      this.fileName = null;
      this.dotExtension = null;
      this.pathCleaned = null;
      this.isSaveFile = false;
      this.$emit("rename", this.fileName )
    }
  },


}

</script>

<template>
  <form @submit.prevent="onSubmit">
    <input @change="handleFileChange" type="file" ref="fileInput" style="display: none" />
    <slot name="header">
      <div class="header"></div>
    </slot>
    <div class="body">
      <slot>

      </slot>
      <slot name="dropzone" :fileBackground="fileBackground" :triggerFileInput="triggerFileInput" :handleDrop="handleDrop">
        <div class="dropzone" :style="fileBackground" @click="triggerFileInput" @drop.prevent="handleDrop" @dragover.prevent
          @dragenter.prevent>
          <div class="content-dropzone" v-if="!waitSmarter">
            <i class="fas fa-cloud-upload-alt fa-1x"></i>
            <span>Cargar archivo</span>
          </div>
          <div v-else class="content-dropzone">
            <!-- Spinner grande -->
            <i class="fas fa-spinner fa-spin-pulse fa-2x text-dark"></i>
          </div>
        </div>
      </slot>
    </div>
    <slot name="footer">
      <div class="footer" v-if="!hiddenFooter">
        <vs-button class="slot-button"   type="submit" :loading="uploading">Guardar</vs-button>
        <slot name="btn-cancel" :onCancel="onCancel">
          <vs-button class="slot-button" @click.prevent="onCancel"   danger>Cancelar</vs-button>
        </slot>
      </div>
    </slot>
  </form>
</template>

<style>
.dropzone {
  width: 150px;
  height: 150px;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.dropzone:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.content-dropzone {
  display: flex;
  align-items: center;
  gap: .5rem;
  color: var(--lego-gris);
}

.content-dropzone span {
  color: var(--lego-gris);
}

.footer {
  display: flex;
  margin-top: 1rem;
  gap: .5rem;
}

.slot-button {
  margin: 0;
}
</style>