<script>
export default {
  props: {
    startDate: {
      type: String,
    },
    dueDate: {
      type: String
    }
  },
  computed: {
    startDateFormatted() {
      return this.startDate ? this.fromBackToFrond(this.startDate) : null
    },
    dueDateFormatted() {
      return this.dueDate ? this.fromBackToFrond(this.dueDate) : null
    }
  },
  data() {
    return {
      localStartDate: '',
      localDueDate: ''
    }
  },
  methods: {
    setDates() {
      this.$emit('setDates', { 
        startDate: this.localStartDate ? this.localStartDate : this.startDateFormatted,
        dueDate: this.localDueDate ? this.localDueDate : this.dueDateFormatted
      })
      // blur en cualquier cosa en la que este
      document.activeElement.blur()
    },
    restoreDate() {
      this.$emit('setDates', { startDate: '', dueDate: ''})
    },
    fromBackToFrond(date) {
      return date.split('T')[0]
    }
  },
  
}
</script>
<template>
  <form class="dateTooltip" @submit.prevent="setDates">
    <div>
      <label for="startDate">
        <span>Fecha de inicio</span>
      </label>
      <input @keydown.prevent class="lego-input" id="startDate" type="date" :value="startDateFormatted" @input="localStartDate = $event.target.value"/>
    </div>
    <div>
      <label for="dueDate">
        <span>Fecha de finalización</span>
      </label>
      <input @keydown.prevent class="lego-input" id="dueDate" type="date" :value="dueDateFormatted" @input="localDueDate = $event.target.value"/>
    </div>
    <div class="controls">
      <slot name="default" :setDates="setDates">

      </slot>
    </div>
  </form>
</template>

<style scoped>

.controls {
  display: flex;
  justify-content: flex-start;
}
</style>