<script>
import LegoBtn from '@/components/Lego/LegoBtn.vue'

export default {
  components: {
    LegoBtn
  },
  props: {
    card: {
      type: Object,
      required: true
    }
  }
}

</script>

<template>
  <router-link v-if="card.isDone" :to="{ name: 'LegoCardProcess', params: { process: 'Validar', cardId: card.id }}">
    <LegoBtn :class="{ success: card.acceptTask, fail: card.acceptTask == 0 }">
      <span>
        <i class="fa-solid fa-hands"></i>
      </span>
      {{ card.isValidate ? 'Validado' : 'Validar' }}
    </LegoBtn>
  </router-link>
</template>