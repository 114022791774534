<script>
export default {

}
</script>

<template>
  <div v-on="$listeners" class="tree-dots">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
</template>

<style scoped>
.tree-dots {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  transition: .5s;
  cursor: pointer;
}

.tree-dots:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.tree-dots > div {
  width: .25rem;
  height: .25rem;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}

</style>
