<script>
import CheckButton from '@/components/Lego/CheckButton.vue';

export default {
  components: {
    CheckButton
  },
  props: {
    card: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    lite: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      waitGetDone: false,
    }
  },
  mounted() {
    if( this.card.id == this.$route.params.cardId ) {
      console.log(this.card);
    }
  },
  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn
    },
    currencyUserIsResponsible() {
      return this.card.assignments.map( a => a.personaId ).includes( this.userLoggedIn.id )
    },
    roleLevel() {
      return this.$store.state.lego.currencyRole.level
    },
    thisSessionIsResponsible() {
      return this.card.responsibleId == this.userLoggedIn.id
    },
    isDone: {
      get() {
        return this.card.isDone
      },
      async set() {
        // restricción de role y de si el usuario es responsable
        if( this.roleLevel > 2 ) {
          if( !this.currencyUserIsResponsible ) {
            return
          }
        } 

        if(this.disabled) return
        const card = {
          id: this.card.id,
          isDone: !this.card.isDone,
          doneBy: this.userLoggedIn.id
        }
        this.waitGetDone = true
        await this.$store.dispatch('lego/setIsDoneCard', card)
        this.waitGetDone = false
      }
    },
  }
}

</script>

<template>
  <CheckButton v-model="isDone" :waiting="waitGetDone" :lite="lite" />
</template>
