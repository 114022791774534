<script>
import AddBtn from '@/components/buttons/AddBtn.vue';

export default {
  components: {
    AddBtn
  },
  props: {
    collaborators: {
      type: Array,
      required: false,
      default: () => []
    },
    selectedCollaborators: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      selected: null
    }
  },
  methods: {
    input() {
      if(!this.selected) return
      this.$emit('input', this.selected)
      this.selected = null
    },
    keydownHandler(ev) {
      if (this.selected) {
        if (ev.keyCode == 13) {
          this.input()
        }
      }
    }
  },
  mounted() {
    document.addEventListener('keydown', this.keydownHandler)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keydownHandler)
  },
  computed: {
    availableCollaborators() {
      return this.collaborators.filter(c => !this.selectedCollaborators.includes(c))
    }
  }
}
</script>

<template>
  <div class="wrapper">
    <v-select class="lego" 
    :options="availableCollaborators" 
    v-model="selected" 
    :get-option-label="(item) => item.name"></v-select>
    <vs-button   class="btn">
      <AddBtn class="plus" @click="input"/>
    </vs-button>
  </div>
</template>

<style scoped>
.lego {
  width: 100%;
  font-size: .8rem;
  ;
}

.wrapper {
  display: flex;
}

.btn {
  margin: 0;
  border-radius: 0 5px 5px 0;
}

.btn :deep(> div) {
  padding: .1rem .3rem;
}

.btn:hover {
  transform: none;
}

.plus {
  height: 24px;
  height: 24px;
}
</style>