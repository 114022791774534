<script>
import Tooltip2Vue from '@/components/Tooltip2Vue/Tooltip2Vue';

export default {
  components: {
    Tooltip2Vue
  },
  props: {
    card: {
      type: Object,
      required: true
    },
    position: {
      type: String,
      default: 'bottom'
    }
  },
  data() {
    return {
      options: [
        { value: null, label: 'No aplica' },
        { value: 0, label: 'No cumple' },
        { value: 1, label: 'Cumple' }
      ]
    }
  },
  computed: {

    auditoriaCalificacion() {
      return this.card.auditoriaCalificacion
    },
    optionValue() {
      return this.options.find(option => option.value === this.auditoriaCalificacion)
    },
    label() {
      if (this.auditoriaCalificacion === null) {
        return 'Calificación'
      } else if (this.auditoriaCalificacion === 0) {
        return 'No cumple'
      } else  {
        return 'Cumple'
      }
    }
  },
  methods: {
    input({value}, close) {
      const card = Object.assign({}, this.card, { auditoriaCalificacion: value })
      this.$store.dispatch('lego/setAsyncItemInCard', card)
      console.log(card);
      close()
    },
  }
}

</script>

<template>
  <Tooltip2Vue :position="position" v-if="card.legoAuditoriaId">
    <div class="btn-shadow">
      <span><i class="fa-regular fa-bookmark"></i></span>
      <span>{{ label }}</span>
    </div>
    <template #tooltip="{ close }">
      <div class="tp-container">
        <div class="mb-2">
          <span>Calificación de la auditoria</span>
        </div>
        <v-select class="lego" :options="options" @input="input($event, close)" :value="optionValue"></v-select>
      </div>
    </template>
  </Tooltip2Vue>
</template>

<style scoped>
.btn-shadow {
  padding: .5rem;
  border: solid 1px rgba(255, 255, 255, 0.25);
  background-color: transparent;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  gap: .5rem;
  font-size: .8rem;
}

.tp-container {
  border: solid 1px rgba(255, 255, 255, 0.25);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.8);
  padding: 1rem;
  border-radius: 8px;
  cursor: auto;
  width: 300px;
  text-align: start;
}
</style>