<script>
import LegoTooltip from '@/components/Lego/LegoTooltip.vue';
import LegoControlCardDate from '@/components/Lego/LegoControlCardDate.vue';
import LegoControlCardDone from '@/components/Lego/LegoControlCardDone.vue';
import LegoControlCardEffort from '@/components/Lego/LegoControlCardEffort.vue';
import LegoControlCardAuditoria from '@/components/Lego/LegoControlCardAuditoria.vue';
// import LegoProgressBar from '@/components/Lego/LegoProgressBar.vue';

export default {
  components: {
    LegoTooltip,
    LegoControlCardDate,
    LegoControlCardDone,
    LegoControlCardEffort,
    LegoControlCardAuditoria,
    // LegoProgressBar
  },
  props: {
    card: {
      type: Object,
      required: true
    },
    disabledControls: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openCard() {
      this.$router.push({ name: 'LegoTarjeta', params: { cardId: this.card.id } })
    },
    deleteCard() {
      this.$store.dispatch('lego/deleteCard', this.card)
    }
  }
}

</script>

<template>
  <div class="card-resumen" @click="openCard">
    <header class="header">
      <div class="card-resumen--title">
        <span>
          {{ card.title }}
        </span>
      </div>
      <div @click.stop class="controls">
        <LegoTooltip position="bottom">
          <span>Relaciones</span>
          <span class="text-lego-danger" @click="deleteCard">Eliminar tarjeta</span>
        </LegoTooltip>
      </div>
    </header>
    <div @click.stop class="tags-container" v-if="!disabledControls">
      <LegoControlCardDone :card="card"  />
      <LegoControlCardDate :card="card" position="right-start" v-if="card.startDate || card.dueDate" />
      <LegoControlCardEffort :card="card" v-if="card.effort" />
      <LegoControlCardAuditoria :card="card" v-if="card.legoAuditoriaId" position="right-start"/>
      <!-- <LegoProgressBar :list="(card.checklists.map( c => c.items).flat())" class="w-100" :color="card.color" /> -->
    </div>
    <div class="tags-container" v-else>
      <LegoControlCardDone disabled :card="card"  />
      <LegoControlCardDate disabled :card="card" position="right-start" v-if="card.startDate || card.dueDate" />
      <LegoControlCardEffort disabled :card="card" v-if="card.effort" />
      <LegoControlCardAuditoria disabled :card="card" v-if="card.legoAuditoriaId" position="right-start"/>
    </div>

  </div>
</template>

<style scoped>
.card-resumen {
  background-color: var(--lego-oscuro);
  cursor: pointer;
  padding: .5rem 1rem;
  border-radius: 4px;
  transition: .3s;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: .5rem;
}

.controls {
  opacity: 0;
  transition: .3s;
}

.card-resumen:hover .controls {
  opacity: 1;
}

.card-resumen--title {
  font-size: 1.1rem;
  font-weight: bold;
}

.tags-container {
  display: flex;
  gap: .5rem;
  flex-wrap: wrap;
}
</style>