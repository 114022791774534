<script>
import LegoControlCardAssignments from '@/components/Lego/LegoControlCardAssignments.vue';
import LegoControlCardDate from '@/components/Lego/LegoControlCardDate.vue';
import LegoControlCardDone from '@/components/Lego/LegoControlCardDone.vue';
import LegoControlCardEffort from '@/components/Lego/LegoControlCardEffort.vue';
import LegoControlCardAuditoria from '@/components/Lego/LegoControlCardAuditoria.vue';
import LegoControlCardAuditoriaCalificacion from '@/components/Lego/LegoControlCardAuditoriaCalificacion.vue';
import LegoControlCardValidate from '@/components/Lego/LegoControlCardValidate.vue';
import LegoControlCardAdjuntos from '@/components/Lego/LegoControlCardAdjuntos.vue';

export default {

  components: {
    LegoControlCardAssignments,
    LegoControlCardDate,
    LegoControlCardDone,
    LegoControlCardEffort,
    LegoControlCardAuditoria,
    LegoControlCardAuditoriaCalificacion,
    LegoControlCardValidate,
    LegoControlCardAdjuntos
    
  },
  data() {
    return {
      waitGetDone: false,
    }
  },
  computed: {
    collaborators() {
      return this.$store.state.lego.board.collaborators
    },
    card() {
      return this.$store.state.lego.card
    },
    roleLevel() {
      return this.$store.state.lego.currencyRole.level
    }
    

  },

}
</script>

<template>
  <div class="header__controls">
    <!-- Problema grande de reactividad, hay que crear una accion para cada uno de ellos -->
    <LegoControlCardDone :card="card"/>
    <LegoControlCardValidate :card="card" v-if="roleLevel <= 2"/>
    <LegoControlCardAuditoria :card="card" v-if="roleLevel <= 2"/>
    <LegoControlCardAuditoriaCalificacion :card="card" v-if="roleLevel <= 2"/>
    <LegoControlCardDate :card="card"/>
    <LegoControlCardAssignments :collaborators="collaborators" :card="card"/>
    <LegoControlCardEffort :card="card"/>
    <LegoControlCardAdjuntos :card="card"/>
  </div>
</template>

<style scoped>
.header__controls {
  display: flex;
  gap: .5rem;
  flex-wrap: wrap;
}

</style>